import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { PageHeader } from "../components/PageHeader"
import { Wrapper } from "../components/Wrappers"
import { BlogPostPreview } from "../components/BlogPostPreview"
import { Pager } from "../components/Pager"
import styled from "styled-components"
const NewWrapper = styled(Wrapper)`
  padding-top: 40px;
  ul {
    margin: 0;
  }
`

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allSanityPost(
      limit: $limit
      skip: $skip
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          publishedAt
          mainImage {
            asset {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
          title
          _rawBody
        }
      }
    }
  }
`

const IndexPage = ({ data, pageContext, errors }) => {
  const posts = data.allSanityPost.edges
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <Layout>
      <SEO
        title="Aktualności | Serwis olejowy Adam Sagan"
        description="Aktualności i promocje u nas w serwisie| Serwis olejowy Adam Sagan"
        keywords="Promocje, serwis, aktualności"
      />
      <PageHeader>Aktualności</PageHeader>
      <NewWrapper>
        <ul>
          {posts &&
            posts.map(post => (
              <React.Fragment key={post.id}>
                <BlogPostPreview post={post} />
                <hr />
              </React.Fragment>
            ))}
        </ul>
        <Pager pageContext={pageContext} />
      </NewWrapper>
    </Layout>
  )
}

export default IndexPage
