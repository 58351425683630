import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StyledLink = styled(Link)`
  color: rgb(207, 37, 51);
  float: ${({ float }) => float};
`
const StyledWrapper = styled.div`
  padding-bottom: 30px;
`

export const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <StyledWrapper>
      {previousPagePath && (
        <StyledLink to={previousPagePath} float="right">
          Zobacz nowsze posty <FontAwesomeIcon icon={faArrowRight} />
        </StyledLink>
      )}
      {nextPagePath && (
        <StyledLink to={nextPagePath} float="left">
          <FontAwesomeIcon icon={faArrowLeft} />
          Zobacz starsze posty
        </StyledLink>
      )}
    </StyledWrapper>
  )
}

Pager.propTypes = {
  pageContext: PropTypes.object.isRequired,
}
