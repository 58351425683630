import styled from "styled-components"
import React from "react"
import Img from "gatsby-image"
import PortableText from "@sanity/block-content-to-react"
import moment from "moment"
import { Link } from "gatsby"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 2em;
  margin-bottom: 30px;
  transition: 0.3s background-color;
  h3 {
    color: black;
  }
  span {
    color: grey;
    font-size: 13px;
  }
  p {
    color: black;
    overflow: hidden;
    max-height: 130px;
    text-overflow: ellipsis;
    font-size: 15px;
  }
  &:hover {
    background: #f1f1f1;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
`
const StyledImg = styled(Img)`
  border-radius: 6px;
  transition: 0.2s filter;
  &:hover {
    filter: grayscale(0.8);
  }
`
const serializers = {
  types: {
    image: props => null,
  },
}
export const BlogPostPreview = ({ post }) => {
  return (
    <StyledLink to={`aktualnosci/${post.node.slug.current}`}>
      <Wrapper>
        <StyledImg fluid={post.node.mainImage.asset.fluid} />
        <div>
          <h3>{post.node.title}</h3>
          <PortableText blocks={post.node._rawBody} serializers={serializers} />
          <span>{moment(post.node.publishedAt).format("DD.MM.YYYY")}</span>
        </div>
      </Wrapper>
    </StyledLink>
  )
}
